import Vue from 'vue'
import {
  Form, Field, Toast, Button, Uploader,
} from 'vant'
import { mapState, mapActions } from 'vuex'
import role from '../../assets/lib/role'
import { sendCode, newregister } from '../../api/registerApi'

const { Base64 } = require('js-base64')


Vue.use(Uploader)
Vue.use(Button)
Vue.use(Form)
Vue.use(Field)
Vue.use(Toast)

export default {
  components: {},
  data () {
    return {
      userPhone: '',
      code: '',
      userPassword: '',
      rePassword: '',
      second: 60,
      isget: true,
      maxLength: '11',
      maxLength1: '6',

      fileListRen: [],
      fileListGuo: [],
      renfile: '',
      guofile: ''
    }
  },
  methods: {
    ...mapActions('home', ['supplementCard', 'uploadImage']),
    back () {
      this.$router.go(-1)
    },

    afterReadRen (file) {

      const data = new FormData()
      data.append("image", file.file)
      this.uploadImage(data).then((res) => {
        if (res.code == 200) {
          this.renfile = res.data

        }
      })
    },
    afterReadGuo (file) {
      // 此时可以自行将文件上传至服务器
      const data = new FormData()
      data.append("image", file.file)
      this.uploadImage(data).then((res) => {
        if (res.code == 200) {
          this.guofile = res.data
          console.log(this.guofile, 666)
        }
      })
    },
    async afterDelete (file) {
      this.isloadImg = true
      return new Promise((resolve, reject) => {
        console.log("删除文件的回调,afterDelete")
        this.isloadImg = false
        this.upload = true
        resolve()
      })
    },
    beforeRead (file) {
      if (file instanceof Array) {
        for (let i = 0; i < file.length; i++) {
          if (file[i].type == 'image/jpeg' || file[i].type == 'image/png') {
            return true
          } else {
            Toast('请上传 png/jpg 格式图片')
            return false
          }
        }
      } else {
        if (file.type == 'image/jpeg' || file.type == 'image/png') {
          return true
        }
        Toast('请上传 png/jpg 格式图片')
        return false
      }
    },
    onSubmit () {
      const flag = this.resVerification()
      if (flag) {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0,
        })
        const data = JSON.stringify({
          verifyCode: this.code,
          rePassword: Base64.encode(this.rePassword),
          password: Base64.encode(this.userPassword),
          phone: this.userPhone,
          openid: localStorage.getItem('openId'),
          accessToken: localStorage.getItem('accessToken'),
          front: this.renfile,
          back: this.guofile,
        })
        newregister(data).then((res) => {
          Toast.clear()
          if (res.code == 200) {
            localStorage.setItem('token', res.token)
            Toast({
              message: '注册成功！',
              forbidClick: true,
              onClose: () => {
                this.$router.push({
                  name: 'Home',
                })
              },
            })
          } else {
            Toast(res.msg)
          }
        })
      }
    },

    // 获取验证码
    getregVers () {
      const phone = this.userPhone
      if (phone !== '') {
        if (!role.phone.test(phone)) {
          Toast('请输入正确手机号')
        } else {
          sendCode({ phone }).then((res) => {
            if (res.code === 200) {
              Toast({
                message: '验证码已发送，请注意查收!',
              })
              this.isget = false
              this.nogetVer()
            } else {
              Toast(res.msg)
            }
          })
        }
      } else {
        Toast('手机号不能为空')
      }
    },
    // 获取验证码
    nogetVer () {
      const that = this
      this.setInnt = setInterval(() => {
        if (that.second > 1) {
          that.second -= 1
        } else {
          clearInterval(that.setInnt)
          that.isget = true
          that.second = 60
        }
      }, 1000)
    },
    // 校验
    resVerification () {
      if (this.userPhone == '') {
        Toast('手机号不能为空！')
        return false
      } if (!role.phone.test(this.userPhone)) {
        Toast('手机号格式错误！')

        return false
      } if (this.code == '') {
        Toast('验证码不能为空！')

        return false
      } if (this.userPassword == '') {
        Toast('密码不能为空！')
        return false
      } if (!role.password.test(this.userPassword)) {
        Toast('密码长度应为8-16位且包含大小写字母、数字！')

        return false
      } if (this.rePassword == '') {
        Toast('请再次输入密码！')
        return false
      } if (this.userPassword != this.rePassword) {
        Toast('两次密码输入不一致！')
        return false
      }
      return true
    },

  },
}
